/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Layout from '../../components/Layout'
import Login from '../../components/Login'
import { useEffect } from 'react';

export default function LoginPanel() {
  const [cookies] = useCookies(["auth.userdata"]);
  let navigate = useNavigate();
  useEffect(() => {
      if(cookies["auth.userdata"] !== undefined){
        navigate("/");
      }
  }, [])


  return (
    <Layout>
      <Login/>
    </Layout>
  )
}
