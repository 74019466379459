/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { UserContext} from '../../context/UserContext';
interface layoutProps {
  children: ReactNode;
  protectedRoute?: boolean | string;
  requiredPermission?: string;
  requiredRole?: string;
}

export default function Layout({children,protectedRoute,requiredPermission,requiredRole}:layoutProps) {
  
  const { hasPermission,hasRole } = useContext(UserContext)
  const [cookies] = useCookies(["auth.userdata"]);
  let navigate = useNavigate();

  useEffect(() => {
    if(protectedRoute|| protectedRoute === "true"){
      if(cookies["auth.userdata"] === undefined){
        navigate("/user/login");
      }

      if(requiredPermission && !hasPermission(requiredPermission)){
        navigate("/");
      }

      if(requiredRole && !hasRole(requiredRole)){
        navigate("/");
      }

    }
  }, [])
  

  return (
    <div className="container mx-auto px-4">
        {children}
    </div>
  )
}
