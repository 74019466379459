import { useContext } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import { UserContext} from '../../context/UserContext'

export default function AdminPanel() {
  const { hasPermission } = useContext(UserContext)
  return (
    <Layout>
      <div className='mt-5 flex gap-3'>
        <Link to="shops"
          className='text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'
        >Painel de lojas jet</Link>
        {hasPermission("user.edit") && <Link to="user"
          className='text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'
        >Painel de usuarios</Link>}

      </div>

    </Layout>
  )
}
