import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout'

export default function NotFound() {
  return (
    <Layout>
      Página não encontrada
      <br></br>
      <Link to="/">
        <span className="button_submit">
          Voltar para a home
        </span>
      </Link>

    </Layout>
  )
}
