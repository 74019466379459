import { useContext } from "react";
import { toast } from "react-toastify";
import { ShopEditorContext } from "../../../context/ShopEditorContext";
import useJetVersions from "../../../hooks/useJetVersions";
import Api from "../../../services/ApiSerie";

const FormEdicao = () => {
    const { formName, setFormName } = useContext(ShopEditorContext);
    const { formVersion, setFormVersion } = useContext(ShopEditorContext);
    const { formLink, setFormLink } = useContext(ShopEditorContext);
    const { formId, setFormId } = useContext(ShopEditorContext);
    const { setSelectedShop } = useContext(ShopEditorContext);

    const jetVersions = useJetVersions();

    const formHandle = (e) => {
        e.preventDefault();

        if (
            formName !== "" &&
            formVersion !== "" &&
            formLink !== "" &&
            formId !== null
        ) {
            var formdata = new FormData();
            formdata.append("shopName", formName);
            formdata.append("shopVersion", formVersion);
            formdata.append("shopLink", formLink);
            formdata.append("shopId", formId.toString());
            Api.post("shop/edit.php", formdata, {
                method: "POST",
            })
                .then((response) => {
                    toast.success("Loja Atualizada com sucesso!");
                    setSelectedShop();
                    setFormName("");
                    setFormVersion("");
                    setFormLink("");
                    setFormId(0);
                })
                .catch((err) => {
                    toast.error("Ops, Algo deu errado");
                });
        } else {
            toast.error("Ops, Algo deu errado");
        }
    };

    const versionChange = (event) => {
        setFormVersion(event.target.value);
    };
    const nameChange = (event) => {
        setFormName(event.target.value);
    };
    const linkChange = (event) => {
        setFormLink(event.target.value);
    };

    return (
        <div>
            <form
                onSubmit={formHandle}
                className=" rounded px-8 pt-6 pb-8 mb-4 text-left"
            >
                <div className="mb-4">
                    <label
                        className="block text-sm font-bold mb-2"
                        htmlFor="SiteName"
                    >
                        ID
                    </label>
                    <input
                        className="ppearance-none block w-full  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none "
                        id="SiteId"
                        type="text"
                        placeholder="id da loja"
                        disabled
                        value={formId}
                    />
                </div>

                <div className="mb-4">
                    <label
                        className="block text-sm font-bold mb-2"
                        htmlFor="SiteName"
                    >
                        Nome da loja
                    </label>
                    <input
                        className="ppearance-none block w-full  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                        id="SiteName"
                        type="text"
                        placeholder="ex: NuttyBavarian"
                        value={formName}
                        onChange={nameChange}
                    />
                </div>
                <div className="mb-4">
                    <label
                        className="block text-sm font-bold mb-2"
                        htmlFor="SiteName"
                    >
                        Versão da loja
                    </label>
                    <div className="relative">
                        <select
                            onChange={versionChange}
                            value={formVersion}
                            className="block appearance-none w-full  border py-3 px-4 pr-8 rounded leading-tight focus:outline-none "
                        >
                            <option value="" disabled hidden>
                                Choose here
                            </option>
                            {jetVersions &&
                                jetVersions.map((item) => (
                                    <option key={item.id}>{item.tag_name}</option>
                                ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                            <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <label
                        className="block text-sm font-bold mb-2"
                        htmlFor="SiteName"
                    >
                        Link da loja
                    </label>
                    <input
                        className="ppearance-none block w-full  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                        id="SiteName"
                        type="text"
                        placeholder="ex: NuttyBavarian"
                        value={formLink}
                        onChange={linkChange}
                    />
                </div>
                <div className="flex items-center justify-between">
                    <input
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        value="Editar site"
                    />
                </div>
            </form>
        </div>
    )
}

export default FormEdicao