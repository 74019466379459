import { ReactNode, useEffect, useState } from "react";
import '../../styles/Tabela.scss';


interface tabelaProps {
  children: ReactNode,
  Colunas: string[],
}

export default function Tabela({children,Colunas}:tabelaProps) {
  const [col, setCol] = useState([]);
  useEffect(() => {
    setCol(Colunas);
    // eslint-disable-next-line
  }, []);

  return (
    <table className="Tabela">
      {Colunas && (
        <thead>
          <tr>
            {col.map((coluna, i) => {
              return <th key={i}>{coluna}</th>;
            })}
          </tr>
        </thead>
      )}
      {children && <tbody>{children}</tbody>}
    </table>
  );
}
