import { useContext } from 'react';
import Layout from '../../../components/Layout'
import UserRegister from '../../../components/User/UserRegister';
import UserTable from '../../../components/User/UserTable';
import { UserContext} from '../../../context/UserContext';
const UserAdmin = () => {
    const { hasPermission } = useContext(UserContext);
    return (
        <Layout protectedRoute={true} requiredPermission='user.edit'>
            <UserTable/>
            <hr />
            {hasPermission("user.create") && <UserRegister/>}
        </Layout>
    )
}

export default UserAdmin