
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { UserContext} from "../../context/UserContext";
import styles from "./login.module.scss";
interface LoginInputs {
    email: string,
    password: string,
}

const Login = () => {
    
    const [showPassword, setShowPassword] = useState(false);
    const {signIn} = useContext(UserContext);

    const { register, handleSubmit } = useForm();
    
    const onSubmit = ({email,password}: LoginInputs) => {
        signIn({email,password});
    };
  
  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.email}>
          <label htmlFor="email">Email</label>
          <input name="email" required {...register("email")} type="text" />
        </div>
        <div className={styles.password}>
          <label htmlFor="senha">Senha</label>
          <input name="senha" required {...register("password")} type={showPassword ? "text" : "password"} />
        </div>
        <div className={styles.showPassword}>
          <label htmlFor="showPassord">Exibir senha</label>
          <input name="showPassord" type="checkbox" onChange={()=>setShowPassword(!showPassword)} checked={showPassword}  />
        </div>
        <input type="submit" className="button_submit" value="Fazer login" />
      </form>
    </>

  )
}

export default Login