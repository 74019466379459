import Menu from "../Menu";
// Estilos
import styles from "./styles.module.scss";

export default function Header() {
  return (
    <div className={styles.header}>
      <div className="container mx-auto">
        <Menu></Menu>
      </div>
    </div>
  )
}