import { useState,useEffect } from "react";
import { getJetArray, getJetVersion } from "../../services/ApiJet";


interface jetTableItemProps {
  siteVersion: string;
  siteName: string;
  siteData: {
    Id:number;
    shopVersion:string;
    shopName:string;
  };
}

const JetTableItem = ({siteVersion,siteName}:jetTableItemProps) => {

  const [myVersion] = useState(siteVersion);
  const [versionJet] = useState(getJetVersion());

  const [desatualizado, setDesatualizado] = useState(0);

  useEffect(() => {
    let jetArray = getJetArray();
    let versoesAtras = 0;
    jetArray.every(element => {
      if (element["tag_name"] === myVersion) {
        setDesatualizado(versoesAtras);
        return false;
      }else{
        versoesAtras += 1;
        return true;
      }
    });
    // eslint-disable-next-line
  }, [])
  

  return (
    <tr className="whitespace-nowrap">
      <td className="px-6 py-4">
        <div className="">{siteName}</div>
      </td>
      <td className="px-6 py-4">
        {myVersion}
      </td>
      <td className="px-6 py-4">{desatualizado} versões</td>
      <td className="px-6 py-4">{(desatualizado > 0 ? ((desatualizado * 1.5)+ 2) : 0) } horas estimadas</td>
      <td className={(myVersion === versionJet ? "bg-green-600" : "bg-red-600") + " px-6 py-4 text-sm text-white"}>
        {myVersion === versionJet ? "Atualizado" : "Desatualizado"}
      </td>
    </tr>
  );
};

export default JetTableItem;