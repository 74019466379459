import axios from "axios";

const ApiKey =
  "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE0Mjg1MjM5NiwidWlkIjozNDMzOTgwLCJpYWQiOiIyMDIyLTAxLTI2VDIxOjQ4OjQ5LjQzNFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjoxNTYzMDE5LCJyZ24iOiJ1c2UxIn0.6lkGRkDvBSKJ3UnIrBaW-XK4wKKoMsaRxeAP-lWQ0wg";
const ApiMonday = axios.create({
  baseURL: `https://api.monday.com/v2`,
  headers: { "Content-Type": "application/json", Authorization: ApiKey },
});

export default ApiMonday;
