import JetAccordion from "../components/Jet/JetAccordion";
import JetTable from "../components/Jet/JetTable";
import Layout from "../components/Layout";
import useJetVersions from "../hooks/useJetVersions";

export default function JetPanel() {

  const jetVersions = useJetVersions();

  const generatePublishDate = (data: Date) : string=>{
    return `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`;
  }

  return (
    <Layout>
      {jetVersions && <div className="mt-5 p-3 bg-indigo-800 rounded inline-block">
        <div>
          <span>Versão atual da jet: </span> <b>{jetVersions[0].tag_name}</b> <br/>
          <span>Publicada em {generatePublishDate(new Date(jetVersions[0].published_at))}</span>
        </div>
      </div>}
      <JetTable />
      <JetAccordion>
        {jetVersions &&
          jetVersions.map((item, index) => {
            return (
              <a
                key={index}
                href={item.html_url}
                rel="noreferrer"
                target="_blank"
                className="JetVersion"
              >
                {item.tag_name}
              </a>
            );
          })}
      </JetAccordion>
    </Layout>
  );
}
