import Layout from '../../components/Layout'
import ShopEditor from '../../components/Shop/ShopEditor'
import ShopRegister from '../../components/Shop/ShopRegister'
import { ShopEditorProvider } from '../../context/ShopEditorContext'

const JetAdmin = () => {

    return (
        <Layout>
            <ShopEditorProvider>
                <ShopRegister />
                <hr></hr>
                <ShopEditor />
            </ShopEditorProvider>
        </Layout>
    )
}

export default JetAdmin