
import { createContext, ReactNode, useState } from 'react'

interface serieProviderProps {
    children: ReactNode
}

type selectedShopType = {
    itemid:string,
    itemname:string,
    itemversion:string,
    itemlink:string
}

export const ShopEditorContext = createContext({});

export const ShopEditorProvider = ({children}:serieProviderProps) => {
    const [formName, setFormName] = useState<string>("");
    const [formVersion, setFormVersion] = useState<string>("");
    const [formLink, setFormLink] = useState<string>("");
    const [formId, setFormId] = useState<number>(0);
    const [selectedShop, setSelectedShop] = useState<selectedShopType>();
    return (
        <ShopEditorContext.Provider value={{selectedShop, setSelectedShop,formName,setFormName,formVersion, setFormVersion,formLink, setFormLink,formId,setFormId}}>
            {children}
        </ShopEditorContext.Provider>
    )
};
