import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import ApiMonday from "../services/ApiMonday";
import { getTimefromClient, sToTime, today } from "../services/getTime";
import Tabela from '../components/Layout/Tabela';
import Select from "react-select";
import { toast } from "react-toastify";

const ClientsPanel = () => {
  const [status, setStatus] = useState();
  const [clientes, setClientes] = useState(0);
  // 
  const [mes, setMes] = useState(parseInt(today.getUTCMonth() + 1));
  const [selectedClientes, setSelectedClientes] = useState();
  //
  const [tarefas, setTarefas] = useState(null);
  const [clienteInfo, setClientesInfo] = useState(null);
  //
  const clientChange = (event) => {
    setSelectedClientes(event.value);
  };
  const clientInputChange = (event) => {
    setSelectedClientes(event.target.value);
  };
  const mesChange = (event) => {
    setMes(event.target.value);
  };
  //
  useEffect(() => {
    if (window.localStorage.getItem("ClientesArray") == null) {
      const $query = `query {
        boards(ids: 492926956) {
        tags {
               name
               id
               color 
           }
        }
      }`;
      ApiMonday.post("/", JSON.stringify({ query: $query }))
        .then((response) => {
          let arrayfinal = [];
          if (response.data.data["boards"][0] !== null) {
            response.data.data["boards"][0].tags.forEach((element) => {
              arrayfinal.push({
                id: element.id,
                value: element.id,
                label: element.name,
                name: element.name,
              });
            });
          }

          window.localStorage.setItem(
            "ClientesArray",
            JSON.stringify(arrayfinal)
          );
          setClientes(JSON.parse(window.localStorage.getItem("ClientesArray")));
          // document.location.reload(true);
        })
        .catch((err) => {
          toast.error("ops! ocorreu um erro" + err);
        });
    } else {
      setClientes(JSON.parse(window.localStorage.getItem("ClientesArray")));
    }
  }, []);
  const HandlegetHours = async (e) => {
    e.preventDefault();
    const $groups = [
      "novo_grupo51310",
      "group_title",
      "novo_grupo13949",
      "novo_grupo85217",
      "novo_grupo88676",
      "novo_grupo2754",
      "novo_grupo87118",
      "novo_grupo71422",
      "novo_grupo88078",
      "novo_grupo6734",
    ]; //Todos os grupos, menos o finalizado

    const returnQuery = (groups, quantity = 200) => {
      const $query = `query {
        boards (ids: 492926956) {
          groups(ids:[${groups}]) {
            id
            title
            items(newest_first: true,limit : ${quantity}) {
              id
              name
              column_values(ids:["tags","n_meros","controle_de_tempo"]) {
                title
                id
                value
              }
            }
          }
        }
      }`;
      return $query;
    };

    console.log("Buscando informações do monday...");
    setStatus("Buscando informações do monday... Isso pode demorar bastante");
    ApiMonday.post("/", JSON.stringify({ query: returnQuery($groups) }))
      .then((response) => {
        let $tarefas = [];
        let $grupos = [];

        ApiMonday.post(
          "/",
          JSON.stringify({ query: returnQuery(["novo_grupo4541"], 500) })
        ).then((finalizados) => {
          const $finalizados = finalizados.data.data["boards"][0]["groups"];

          console.log("Request do monday completa");
          setStatus("Request do monday completa");
          setTimeout(() => {
            setStatus(null);
          }, 2000);

          if (response.data.data["boards"] !== undefined) {
            $grupos = [
              ...response.data.data["boards"][0]["groups"],
              ...$finalizados,
            ];

            $grupos.forEach((grupo) => {
              grupo["items"].forEach((job) => {
                const nome = job["name"];
                let cliente = null;
                let timers = null;
                let estimativa = null;

                job["column_values"].forEach((coluna) => {
                  switch (coluna.id) {
                    case "tags":
                      if (JSON.parse(coluna.value) !== null) {
                        cliente = JSON.parse(job["column_values"][0].value)[
                          "tag_ids"
                        ][0];
                      }
                      break;
                    case "controle_de_tempo":
                      if (JSON.parse(coluna.value) !== null) {
                        timers = JSON.parse(coluna.value);
                      }
                      break;
                    case "n_meros":
                      if (coluna.value !== null) {
                        estimativa = parseFloat(coluna.value.replace('"', ""));
                      }

                      break;
                    default:
                      break;
                  }
                });

                if (
                  timers !== null &&
                  timers["running"] === "false" &&
                  cliente !== null
                ) {
                  $tarefas.push({
                    nome,
                    cliente,
                    timers,
                    estimativa,
                    label: nome,
                    value: nome,
                  });
                }
              });
            });
          }

          //
          const $tarefasCliente = getTimefromClient($tarefas, selectedClientes,parseInt(mes - 1));
          setTarefas($tarefasCliente["jobs"]);
          setClientesInfo($tarefasCliente);
        });
        return $grupos;
      })
      .catch((err) => {
        toast.error("ops! ocorreu um erro" + err);
        return err;
      });
    //
  };
  return (
    <Layout className="MondayPanel">
      <div className="grid grid-cols-4 gap-4 mb-5 items-center">
        <form onSubmit={HandlegetHours}>
          <input
            className="button_submit"
            type="submit"
            value="Puxar horas do mês"
          />
        </form>
        <Select className='react-select-container' classNamePrefix="react-select"  options={clientes} onChange={clientChange} />
        <div>
          <input
            value={selectedClientes}
            onChange={clientInputChange}
            type="text"
            placeholder="use caso não apareça no select"
            className="text-center"
          />
        </div>
        <div>
          <input
            value={mes}
            onChange={mesChange}
            type="number"
            placeholder="Mes escolhido"
            className="text-center"
            min="1"
            max="12"
          />
        </div>
      </div>
      <div className="status my-5">{status}</div>
      {clienteInfo && (
        <div className="info my-5 grid grid-cols-4 gap-4 items-center text-left">
          <div>Id no filtro: {clienteInfo["cliente"]}</div>
          <div>Quantidade de tarefas no filtro: {clienteInfo["jobs"].length}</div>
          <div>Tempo no filtro: {clienteInfo["total"].string}</div>
          <div>Tempo estimado no filtro: {clienteInfo["estimado"]}</div>
        </div>
      )}
      <Tabela Colunas={["ID","Nome","Duração","Estimativa"]}>
          {tarefas &&
            tarefas.map((tarefa, i) => {
              return (
                <tr key={i}>
                  <td className="px-6 py-4">{tarefa.cliente}</td>
                  <td className="px-6 py-4">{tarefa.label}</td>
                  <td className="px-6 py-4">
                    {sToTime(tarefa["timers"].duration)}
                  </td>
                  <td className="px-6 py-4">{tarefa.estimativa}</td>
                </tr>
              );
            })}
      </Tabela>
    </Layout>
  );
};

export default ClientsPanel;
