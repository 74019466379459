import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { ReactNode, useState } from "react";
import styles from './styles.module.scss'

type jetAccordionProps = {
  children: ReactNode
}
const JetAccordion = ({ children }:jetAccordionProps) => {
  const [open, setOpen] = useState(false);
  const changeOpen = () => {
    setOpen(!open);
  };
  return (
    <div className={styles.JetAccordion}>
      <div className={styles.JetAccordion_button} onClick={changeOpen}>
        Ver lista de versões
        <span className="block">
          {open ? <MinusIcon height={25}/> :  <PlusIcon height={25}/>}
        </span>
      </div>
      <div className={(open ? "block" : "hidden") + " flex flex-wrap mt-0 gap-3 py-3 " + styles.JetAccordion_container}>{children}</div>
    </div>
  );
};

export default JetAccordion;
