interface tarefaInterface {
  id: string;
  name: string;
  column_values: Array<{
    title: string;
    id: string;
    value: string | null;
  }>
}

interface timeWorkedArrayGrupos {
  id: string;
  title: string;
  items: Array<tarefaInterface>
}


export const today = new Date();

export const msToTime = (duration: number) => {
  let milliseconds: number = Math.floor((duration % 1000) / 100),
    seconds: number = Math.floor((duration / 1000) % 60),
    minutes: number = Math.floor((duration / (1000 * 60)) % 60),
    hours: number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hoursString = hours < 10 ? "0" + hours : hours;
  const minutesString = minutes < 10 ? "0" + minutes : minutes;
  const secondsString = seconds < 10 ? "0" + seconds : seconds;

  return hoursString + ":" + minutesString + ":" + secondsString + "." + milliseconds;
};
export const sToTime = (duration: number) => {
  const d: number = Number(duration);
  const h: number = Math.floor(d / 3600);
  const m: number = Math.floor((d % 3600) / 60);
  const s: number = Math.floor((d % 3600) % 60);

  let secondsString;
  let hourString;
  let minuteString;

  if (h < 10) {
    hourString = "0" + h;
  }
  if (m < 10) {
    minuteString = "0" + m;
  }
  if (s < 10) {
    secondsString = "0" + s;
  }
  const time = hourString + ":" + minuteString + ":" + secondsString;
  return time;
};

export const isToday = (someDate: Date) => {
  const today = new Date();
  const istoday =
    someDate.getUTCDate() === today.getUTCDate() &&
    someDate.getUTCMonth() === today.getUTCMonth() &&
    someDate.getFullYear() === today.getFullYear();
  return istoday;
};

export const timeWorked = ($arrayGrupos: timeWorkedArrayGrupos[], $dataSelecionada: number, $userId: number) => {
  const getTempoNaTarefa = (tarefa: tarefaInterface, $userId: number, $dataSelecionada: number) => {

    const today = $dataSelecionada;
    const timers = tarefa.column_values;
    let tempoFinal = 0;
    let $tarefa = tarefa;
    let diaTrabalhado: {total:number,tarefas:any[]} = {
      total: 0,
      tarefas: [],
    };
    timers.forEach((timer: any) => {
      let value = JSON.parse(timer["value"]);
      if (value != null) {
        if (typeof value["additional_value"] !== "undefined") {
          value["additional_value"].forEach((element: any) => {      
            if (parseInt(element["started_user_id"]) === $userId) {
              const hoje = new Date(today);
              const tarefa = new Date(element["started_at"]);
              if (hoje.getUTCDate() === tarefa.getUTCDate() && hoje.getUTCMonth() === tarefa.getUTCMonth() && hoje.getUTCFullYear() === tarefa.getUTCFullYear()) {
                const inicioTarefa: any = new Date(element["started_at"]);
                const fimTarefa: any = new Date(element["ended_at"]);
                const diffTime = Math.abs(fimTarefa - inicioTarefa);
                if (element["ended_at"] != null) {
                  if (diffTime >= 0) {
                    diaTrabalhado.tarefas.push({
                      name: $tarefa.name,
                      id: $tarefa.id,
                      area: timer.title,
                      tempo: msToTime(diffTime),
                      inicioTarefa: inicioTarefa.getTime(),
                      fimTarefa: fimTarefa.getTime()
                    });
                  }
                  tempoFinal += diffTime;
                }
              }
            }
          });
        }
      }
    });
    diaTrabalhado.total = tempoFinal;
    return diaTrabalhado;
  };
  let tempoFinal = 0;
  let tarefasFinal: any[] = [];
  $arrayGrupos.forEach((grupo) => {
    grupo.items.forEach((tarefa) => {
      if (tarefa !== undefined) {
        let gettempo = getTempoNaTarefa(tarefa, $userId, $dataSelecionada);
        let tempoNessaTarefa = gettempo.total;
        tarefasFinal.push(gettempo.tarefas);
        tempoFinal += tempoNessaTarefa;
        // let debug = { name: tarefa.name, time: tempoNessaTarefa };
      }
    });
  });
  tarefasFinal = tarefasFinal.filter((e) => e.length);

  let FinalResult = {
    tempoFinal: msToTime(tempoFinal),
    tarefas: tarefasFinal,
    FinalMs: tempoFinal,
  };
  return FinalResult;
};

// Funções para o mondayPanel
export const getTimefromClient = ($array: any, $cliente: any, $mes: any) => {
  let $tarefas: any[] = [];
  let validation = true;
  $array.forEach((element: any) => {
    if ($cliente !== undefined) {
      validation = element["cliente"] === parseInt($cliente);
    }
    if (validation) {
      const changed_at = new Date(element["timers"].changed_at);
      if (changed_at.getUTCMonth() === $mes) {
        $tarefas.push(element);
      }
    }
  });

  return {
    cliente: parseInt($cliente),
    jobs: $tarefas,
    total: getTotalTime($tarefas),
    estimado: getTimeEstimado($tarefas),
  };
};

export const getTimeEstimado = ($array: any) => {
  let total = 0;
  $array.forEach((element: any) => {
    if (isNaN(parseFloat(element["estimativa"])) === false) {
      total += parseFloat(element["estimativa"]);
    }
  });
  return total;
};

export const getTotalTime = ($array: any) => {
  let total = 0;
  $array.forEach((element: any) => {
    total += element["timers"].duration;
  });
  return { value: total, string: sToTime(total) };
};
