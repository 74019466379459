import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Api from "../../../services/ApiSerie";

const ChangePassword = () => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const [cookies] = useCookies(["auth.userdata"]);
    const onSubmit = (data: any) => {

        if (cookies["auth.userdata"] !== undefined) {
            if(data.newPassword === data.newPasswordConfirmation){
                let formdata = new FormData();
                formdata.append("password", data.newPassword.toString());
                formdata.append("id", cookies["auth.userdata"].id.toString());
    
    
                Api.post("user/changePassword.php", formdata, {
                    method: 'POST',
                }).then((response) => {
                    toast.success("Senha alterada com sucesso");
                    reset();
                }).catch((err) => {
                    console.log(err);
                    toast.error("Ops, Algo deu errado");
                })
            }else{
                toast.error("A confirmação e senha devem ser iguais");
            }
        }

    }
    return (
        <div className="mt-3 p-5 bg-ebony-800 text-white rounded">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label htmlFor="first_name" className="block mb-2 text-sm font-medium">Nova senha</label>
                    <input {...register("newPassword", { required: true })} type="password" className="bg-ebony-900 border border-ebony-50 text-ebony-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                </div>
                <div className="mt-3">
                    <label htmlFor="first_name" className="block mb-2 text-sm font-medium">Confirmação de senha</label>
                    <input {...register("newPasswordConfirmation", { required: true })} type="password" className="bg-ebony-900 border border-ebony-50 text-ebony-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                </div>
                {errors.exampleRequired && <span>This field is required</span>}
                <input value="Enviar" className="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" />
            </form>
        </div>
    )
}

export default ChangePassword