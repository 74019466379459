import { CogIcon } from "@heroicons/react/solid";
import React from "react";
import Layout from "../components/Layout";

const Settings = () => {
  const clearCaches = () => {
    localStorage.clear();
    sessionStorage.clear();

    let retorno = "Cache limpo";
    alert(retorno);
  };
  const SettingButton = ({execute,name}) => {
    return <button onClick={execute} className="button_submit">
    <CogIcon height={25} className="mr-3"></CogIcon>
    <span>{name}</span>
  </button>
  }

  return (
    <Layout>
        <div className="grid grid-cols-6 gap-4">
            <SettingButton name="Limpar cache" execute={() => clearCaches()}/>
        </div>
    </Layout>
  );
};

export default Settings;
