import FormEdicao from "./FormEdicao";
import SeletorEdicao from "./SeletorEdicao";

const ShopEditor = () => {
 
  return (
    
    <div className="mt-5">
      <div className="container mt-5 mx-auto">
          <SeletorEdicao/>
          <hr />
          <FormEdicao  />
      </div>
    </div>

  );
};

export default ShopEditor;
