
import { createContext, ReactNode, useState } from 'react'
import Api from '../services/ApiSerie';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
interface serieProviderProps {
    children: ReactNode
}

interface SignInCredentials {
    email: string,
    password: string,
}
interface UserInfo {
    email: string,
    id: number,
    mondayId: number,
    name: string,
    permissions?: string,
    roles?: string
}
interface loginInterface {
    status: number
    data: {
        message: string
        status: string
    }
}

type AuthContextData = {
    signIn: (credentials: SignInCredentials) => Promise<void>;
    signOut: () => void,
    getUserInfo: (email: string) => UserInfo,
    setUserInfo: (userData: UserInfo) => void,
    isAuthenticated: boolean,
    hasPermission: (permission:string)=>boolean,
    hasRole: (permission:string)=>boolean
    user: UserInfo;
}


export const UserContext = createContext({} as AuthContextData);

export const UserContextProvider = ({ children }: serieProviderProps) => {
    const [user, setUser] = useState<UserInfo>();
    const [cookies, setCookie, removeCookie] = useCookies(["auth.userdata"]);
    let isAuthenticated = cookies["auth.userdata"] !== undefined;
    let navigate = useNavigate();

    const hasPermission = (permission:string) => {
        return cookies['auth.userdata'].permissions.includes(permission);
    }
    const hasRole = (role:string) => {
        return cookies['auth.userdata'].roles.includes(role);
    }
    const getUserInfo = (email: string): UserInfo | any => {

        Api.get("user/getUser.php?email=" + email).then((response) => {

            const data = response.data;
            const userData: UserInfo = {
                email: data.user_email,
                id: Number(data.user_id),
                mondayId: Number(data.user_mondayId),
                name: data.user_name,
                permissions: JSON.parse(data.user_permissions),
                roles: JSON.parse(data.user_roles),
            }

            setUserInfo(userData);
            const urlParams = new URLSearchParams(window.location.search);
            const returnParam = urlParams.get('return');
            if (returnParam) {
                navigate('/' + returnParam);
            } else {
                navigate('/');
            }


        }).catch((error) => {
            toast.error("Login ou senha incorretos");
        })
    };

    const setUserInfo = (userData: UserInfo) => {
        setCookie("auth.userdata", JSON.stringify(userData), { path: '/' });
        setUser(userData);

    }

    async function signIn({ email, password }: SignInCredentials) {
        try {
            var formdata = new FormData();
            formdata.append("email", email);
            formdata.append("password", password);
            Api.get(`user/validateUser/index.php?email=${email}&password=${password}`).then((response: loginInterface) => {
                getUserInfo(email);
            }).catch((error) => {
                toast.error("Login ou senha incorretos");
            })
        } catch (err) {
            console.error(err);
        }
    };

    function signOut() {
        removeCookie("auth.userdata",{path:'/'});
        isAuthenticated = false;
        navigate("/user/login");
    }

    return (
        <UserContext.Provider value={{ signIn, getUserInfo, user, setUserInfo, isAuthenticated, signOut,hasPermission,hasRole }}>
            {children}
        </UserContext.Provider>
    )
};
