import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout'
import TimeWorked from '../../components/User/TimeWorked';

interface UserData {
  email: string
  id:number
  mondayId:number
  name:string
  permissions:string[]
  roles:string[]
}

const Dev = () => {
  const [cookies] = useCookies(["auth.userdata"]);
  const [user, setUser] = useState<UserData>();
  const [loaded, setLoaded] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    if(cookies["auth.userdata"] !== undefined){
      setUser(cookies["auth.userdata"]);
      setLoaded(true);
    }else{
      navigate("/user/login?return=dev");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout protectedRoute="true">
      {loaded && <>
        <TimeWorked id={user.mondayId}></TimeWorked>
      </>}
    </Layout>
  )
}

export default Dev