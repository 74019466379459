import { useState } from 'react';
import { toast } from 'react-toastify';
import useJetVersions from '../../../hooks/useJetVersions';
import Api from "../../../services/ApiSerie";

const ShopRegister = () => {
    const jetVersions = useJetVersions();
    const [formName, setFormName] = useState("");
    const [formVersion, setFormVersion] = useState("");

    const versionChange = (event) => {
      setFormVersion(event.target.value);
    };
    const nameChange = (event) => {
      setFormName(event.target.value);
    };

    const formHandle = (e) => {
      e.preventDefault();
      if (formName !== "" || formVersion !== "") {
        var formdata = new FormData();
        formdata.append("shopName", formName);
        formdata.append("shopVersion", formVersion);
        formdata.append("shopLink", null);
        Api.post("shop/",formdata,{
          method: 'POST',
          redirect: 'follow'
        }).then((response)=>{
          toast.success("Loja Cadastrada com sucesso");
        }).catch((err)=>{
          toast.error("Ops, Algo deu errado");
        })
      }else{
        toast.error("Ops, Algo deu errado");
      }
    };
  
  
    return (
      <div>
        <div className="my-5">
          <form
            onSubmit={formHandle}
            className="rounded px-8 pt-6 pb-8 mb-4 text-left"
          >
            <div className="mb-4">
              <label
                className="block text-sm font-bold mb-2"
                htmlFor="SiteName"
              >
                Nome da loja
              </label>
              <input
                className="ppearance-none block w-full border rounded py-3 px-4 mb-3 leading-tight"
                id="SiteName"
                type="text"
                placeholder="ex: NuttyBavarian"
                value={formName}
                onChange={nameChange}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-bold mb-2"
                htmlFor="SiteName"
              >
                Versão da loja
              </label>
              <div className="relative">
                <select
                  onChange={versionChange}
                  value={formVersion}
                  className="block appearance-none w-full py-3 px-4 pr-8 rounded leading-tight focus:outline-none "
                >
                  <option value="" defaultValue disabled hidden>
                    Choose here
                  </option>
                  {jetVersions &&
                    jetVersions.map((item) => (
                      <option key={item.id}>{item.tag_name}</option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 ">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
  
            <div className="flex items-center justify-between">
              <input
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                value="Cadastrar novo site"
              />
            </div>
          </form>
  
        </div>
      </div>
    );
};

export default ShopRegister;
