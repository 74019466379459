import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout'
import ChangePassword from '../../components/User/ChangePassword';
import UserInfo from '../../components/User/UserInfo';

interface UserData {
  email: string
  id: number
  mondayId: number
  name: string
  permissions: string[]
  roles: string[]
}

const User = () => {
  const [cookies] = useCookies(["auth.userdata"]);
  const [user, setUser] = useState<UserData>();
  const [loaded, setLoaded] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    if (cookies["auth.userdata"] !== undefined) {
      setUser(cookies["auth.userdata"]);
      setLoaded(true);
    } else {
      navigate("/user/login?return=dev");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout protectedRoute="true">
      {loaded && <>
        <div className='mt-3 flex gap-3 content-center items-center'>
          <UserInfo userId={user.mondayId} />
        </div>

        <div className="text-left py-4 ">
          <Link
            to={"/user/dev"}
          >
            <div className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
              <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">Opa!</span>
              <span className="font-semibold mr-2 text-left flex-auto">Procurando pelas suas horas diarias?</span>
              <svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" /></svg>
            </div>
          </Link>
        </div>
        <hr />
        <ChangePassword/>
      </>}
    </Layout>
  )
}

export default User