import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

type commitType = {
    id: string,
    short_id: string,
    created_at: string,
    parent_ids: string[],
    title: string,
    message: string,
    author_name: string,
    author_email: string,
    authored_date: string,
    committer_name: string,
    committer_email: string,
    committed_date: string,
    web_url: string

};

export default function Changlog() {
  const [commits, setCommits] = useState<commitType[]>();

  const getLastCommits = () => {
    if (window.sessionStorage.getItem("CommitsHistory") == null) {
      const myHeaders = new Headers();
      myHeaders.append("PRIVATE-TOKEN", "glpat-vw-xw7CVhk88UX5F8Tni");

      const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        "https://gitlab.com/api/v4/projects/33052319/repository/commits",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          window.sessionStorage.setItem("CommitsHistory", result);
          setCommits(JSON.parse(result));
        })
        .catch((error) => console.log("error", error));
    } else {
      setCommits(JSON.parse(window.sessionStorage.getItem("CommitsHistory")));
    }
  };

  useEffect(() => {
    getLastCommits();
  }, []);
  return (
    <div className={styles.commits}>
      <h2>
        <b>Ultimas novidades no projeto</b>
      </h2>
      <ul className={styles.commits_container}>
        {commits &&
          commits.map((item) => {
            const date = new Date(item["committed_date"]);
            return (
              <li key={item.id} className={styles.commits_item}>
                <a href={item.web_url} target="_blank" rel="noreferrer">
                  <span className={styles.commits_item__date}>
                    {date.getDate() +
                      "/" +
                      (date.getMonth() + 1) +
                      "/" +
                      date.getFullYear()}
                  </span>
                  <span className={styles.commits_item__id}>{item.short_id}</span>
                  <span className={styles.commits_item__title}>{item.title}</span>
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
