import axios from 'axios';

export const JetApi = axios.create({
  baseURL: `https://api.github.com/repos/jetebusiness/NEO-Default_Theme`
});

const requestJetVersion = () => {
  if(window.sessionStorage.getItem('jetVersion') == null || window.sessionStorage.getItem('jetArray') == null){
    JetApi
    .get("/releases")
    .then((response) => {
        window.sessionStorage.setItem('jetArray',JSON.stringify(response.data));
        window.sessionStorage.setItem('jetVersion',response.data[0].tag_name);

        document.location.reload();
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    });
}
}
export const getJetVersion = () => {
  requestJetVersion();
  const jetVersion:String = window.sessionStorage.getItem('jetVersion')
  return jetVersion;
}

type getJetArrayType = {
  assets: string[];
  assets_url: string;
  author: object;
  body: string;
  created_at: string;
  draft: boolean;
  html_url: string;
  id: number;
  name: string;
  node_id: string;
  prerelease: boolean;
  published_at: string;
  tag_name: string;
  tarball_url: string;
  target_commitish: string;
  upload_url: string;
  url: string;
  zipball_url: string;
}

export const getJetArray = () => {
  requestJetVersion();
  const jetArray:getJetArrayType[] = JSON.parse(window.sessionStorage.getItem('jetArray'));
  return jetArray;
}