import { useEffect, useState } from "react";
import { getJetVersion } from "../services/ApiJet";

type author = {
  login: string;
  id: number;
  node_id: string;
  avatar_url: string;
  gravatar_id: string;
  url: string;
  html_url: string;
  followers_url: string;
  following_url: string;
  gists_url: string;
  starred_url: string;
  subscriptions_url: string;
  organizations_url: string;
  repos_url: string;
  events_url: string;
  received_events_url: string;
  type: string;
  site_admin: boolean;
}

type assets = {
  url: string;
  id: number;
  node_id: string;
  name: string;
  label: null;
  uploader: {
    login: string;
    id: number;
    node_id: string;
    avatar_url: string;
    gravatar_id: string;
    url: string;
    html_url: string;
    followers_url: string;
    following_url: string;
    gists_url: string;
    starred_url: string;
    subscriptions_url: string;
    organizations_url: string;
    repos_url: string;
    events_url: string;
    received_events_url: string;
    type: string;
    site_admin: boolean;
  };
  content_type: string;
  state: string;
  size: number;
  download_count: number;
  created_at: string;
  updated_at: string;
  browser_download_url: string;
}
interface jetVersionType {
  url: string;
  assets_url: string;
  upload_url: string;
  html_url: string;
  id: number;
  author: author;
  node_id: string;
  tag_name: string;
  target_commitish: string;
  name: string;
  draft: boolean;
  prerelease: boolean;
  created_at: string;
  published_at: string;
  assets: Array<assets>;
  tarball_url: string;
  zipball_url: string;
  body: string;
}

const useJetVersions = ():jetVersionType[] => {
    const [jetVersions, setJetVersions] = useState();
    useEffect(() => {
        getJetVersion();
        if (window.sessionStorage.getItem("jetArray") != null) {
          setJetVersions(JSON.parse(window.sessionStorage.getItem("jetArray")));
        }
    }, []);

    return jetVersions;
}

export default useJetVersions