import { useEffect, useState } from "react";
import Api from "../../services/ApiSerie";
import JetTableItem from "./JetTableItem";
import Tabela from "../Layout/Tabela";
import { toast } from "react-toastify";

type jetTableItem = {
  Id:number;
  shopVersion:string;
  shopName:string;
}


export default function JetTable() {
  const [shops, setShops] = useState<jetTableItem[]>();
  useEffect(() => {
    Api.get("shop/")
      .then((response) => {
        setShops(response.data);
      })
      .catch((err) => {
        toast.error("ops! ocorreu um erro" + err);
        return err;
      });
  }, []);

  return (
    <div>
      <Tabela Colunas={["Nome", "Site", "Status", "Estimativa", ""]}>
        {shops &&
          shops.map((item) => (
            <JetTableItem
              key={item.Id}
              siteData={item}
              siteVersion={item.shopVersion}
              siteName={item.shopName}
            />
          ))}
      </Tabela>
      <div className="mb-5" >(Estimativa: 1.5hr por versão + 2 horas de correções)</div>
    </div>
  );
}
