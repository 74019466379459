import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Api from '../../../services/ApiSerie';

const UserRegister = () => {
  const { register, reset, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data: any) => {

      let formdata = new FormData();
      formdata.append("password", data.passWord);
      formdata.append("mondayId", data.mondayID);
      formdata.append("name", data.userName);
      formdata.append("email", data.userEmail);

      Api.post("user/", formdata, {
        method: 'POST',
      }).then((response) => {
        toast.success("Senha alterada com sucesso");
        reset();
      }).catch((err) => {
        toast.error("Ops, Algo deu errado "+  err.response.data);
      })
    
  
  }
  return (
    <div className="mt-3 p-5 bg-ebony-800 text-white rounded">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="first_name" className="block mb-2 text-sm font-medium">Nome</label>
          <input {...register("userName", { required: true })} type="text" className="bg-ebony-900 border border-ebony-50 text-ebony-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div className="mt-3">
          <label htmlFor="first_name" className="block mb-2 text-sm font-medium">mondayID</label>
          <input {...register("mondayID", { required: true })} type="text" className="bg-ebony-900 border border-ebony-50 text-ebony-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div className="mt-3">
          <label htmlFor="first_name" className="block mb-2 text-sm font-medium">Email</label>
          <input {...register("userEmail", { required: true })} type="email" className="bg-ebony-900 border border-ebony-50 text-ebony-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        <div className="mt-3">
          <label htmlFor="first_name" className="block mb-2 text-sm font-medium">Senha</label>
          <input {...register("passWord", { required: true })} type="password" className="bg-ebony-900 border border-ebony-50 text-ebony-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
        </div>
        {errors.exampleRequired && <span>This field is required</span>}
        <input value="Enviar" className="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" />
      </form>
    </div>
  )
}

export default UserRegister