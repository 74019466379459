// Paginas
import AdminPanel from "../../pages/AdminPanel";
import JetAdmin from "../../pages/AdminPanel/JetAdmin";
import Home from "../../pages/Home";
import NotFound from "../../pages/NotFound";
import Settings from "../../pages/Settings";
import JetPanel from "../../pages/JetPanel";
import ClientsPanel from "../../pages/ClientsPanel";
import LoginPanel from "../../pages/User/LoginPanel";
// 
import { Navigate, Route, Routes } from 'react-router-dom';
import User from "../../pages/User/User";
import Dev from "../../pages/User/Dev";
import UserAdmin from "../../pages/AdminPanel/UserAdmin";


const Router = () => {
    return (
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route index element={<Home />} />
            <Route path="admin">
                <Route index element={<AdminPanel />} />
                <Route path="shops" element={<JetAdmin />} />
                <Route path="user" element={<UserAdmin />} />
            </Route>
            <Route path="user">
                <Route index element={<User />} />
                <Route path="dev" element={<Dev />} />
                <Route path="login" element={<LoginPanel />} />
            </Route>
            <Route path="dev" element={<Navigate replace to="/user/dev" />} />
            <Route path="dev/:id" element={<Navigate replace to="/user/dev" />} />
            <Route path="jet" element={<JetPanel />} />
            <Route path="clients" element={<ClientsPanel />} />
            <Route path="settings" element={<Settings />} />
        </Routes>
    )
}

export default Router