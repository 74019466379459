import { Link } from "react-router-dom";
import MenuItem from "./MenuItem";
// Estilos
import styles from './styles.module.scss';
// Icones
import { ClockIcon, DatabaseIcon, HomeIcon } from "@heroicons/react/solid";
import { MdAdminPanelSettings } from 'react-icons/md'
import { RiLogoutBoxLine, RiLoginBoxLine, RiUserFill, RiSettings2Fill } from 'react-icons/ri'
import { UserContext} from "../../context/UserContext";
import { useContext } from "react";

export default function Menu() {

  const { isAuthenticated, signOut, hasPermission } = useContext(UserContext)
  return (
    <nav className={styles.menuContainer}>
      <Link to="/">
        <MenuItem>
          <HomeIcon height={25} className="mr-3" />
        </MenuItem>
      </Link>
      <Link to="jet">
        <MenuItem>
          <DatabaseIcon height={25} className="mr-3" />
        </MenuItem>
      </Link>
      <Link to="clients">
        <MenuItem>
          <ClockIcon height={25} className="mr-3" />
        </MenuItem>
      </Link>
      {isAuthenticated && <Link
        to={"/user/dev"}
        className="mr-auto ml-auto"
      >
        <div className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
          <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">Opa!</span>
          <span className="font-semibold mr-2 text-left flex-auto">Procurando pelas suas horas diarias?</span>
          <svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" /></svg>
        </div>
      </Link>}
      <Link to="Settings" className="ml-auto">
        <MenuItem>
          <RiSettings2Fill height={25} className="mr-3" />
        </MenuItem>
      </Link>
      {
        isAuthenticated && (<>
          {hasPermission("dev.all") && <><Link to="admin/">
            <MenuItem>
              <MdAdminPanelSettings height={25} className="mr-3" />
            </MenuItem>
          </Link>
            <Link to="user/">
              <MenuItem>
                <RiUserFill height={25} className="mr-3" />
              </MenuItem>
            </Link>
          </>}

          <button onClick={() => { signOut() }}>
            <MenuItem>
              <RiLogoutBoxLine height={35} className="mr-3" />
            </MenuItem>
          </button>
        </>)
      }
      {
        !isAuthenticated && (<>
          <Link to="user/login">
            <MenuItem>
              <RiLoginBoxLine height={25} className="mr-3" />
            </MenuItem>
          </Link>
        </>)
      }
    </nav>
  );
}
