
import { PencilIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ShopEditorContext } from "../../../context/ShopEditorContext";
import Api from "../../../services/ApiSerie";
import Tabela from "../../Layout/Tabela";

const SeletorEdicao = () => {
    const [jetShops, setJetShops] = useState([]);
    const { setFormName, setFormVersion, setFormLink, setFormId, setSelectedShop } = useContext(ShopEditorContext);
    // eslint-disable-next-line no-unused-vars

    const getShops = () => {
        Api
            .get("shop/")
            .then((response) => {
                setJetShops(response.data);
            })
            .catch((err) => {
                toast.error("ops! ocorreu um erro" + err);
                return err;
            });
    }

    useEffect(() => {
        getShops();
    }, []);


    // Forms Handle
    const selectEditor = (e) => {
        const itemid = e.currentTarget.getAttribute("data-id");
        const itemname = e.currentTarget.getAttribute("data-name");
        const itemversion = e.currentTarget.getAttribute("data-version");
        const itemlink = e.currentTarget.getAttribute("data-link");

        const data = { itemid, itemname, itemversion, itemlink };

        setSelectedShop(data);
        setFormName(itemname);
        setFormVersion(itemversion);
        setFormLink(itemlink);
        setFormId(itemid);

    };
    return (
        <div className="flex justify-center mx-auto mt-5">
            <div className="flex flex-col w-full">
                <div className="w-full">
                    <div className="w-full">
                        <Tabela Colunas={["ID", "Name", "shopVersion", "shopLink", "edit"]}>
                            {jetShops &&
                                jetShops.map((item) => (
                                    <tr key={item.Id} className="whitespace-nowrap">
                                        <td className="px-6 py-4">
                                            <div className="text-sm">
                                                {item.Id}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm">
                                                {item.shopName}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm">
                                                {item.shopVersion}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm">
                                                {item.shopLink}
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                data-id={item.Id}
                                                data-name={item.shopName}
                                                data-version={item.shopVersion}
                                                data-link={item.shopLink}
                                                onClick={selectEditor}
                                                className="mx-auto  flex px-2 py-1 rounded-lg items-center"
                                            >
                                                <PencilIcon className="h-5 w-5" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </Tabela>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeletorEdicao