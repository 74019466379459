/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { isToday, msToTime, timeWorked } from "../../services/getTime";
import ApiMonday from "../../services/ApiMonday";
import Tabela from "../Layout/Tabela";
import { toast } from "react-toastify";

const TimeWorked = ({ ...props }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [userTime, setUserTime] = useState(null);
  const [dayTime, setDayTime] = useState(null);
  const [percent, setPercent] = useState(1);
  // Filtros
  const [check01, setCheck01] = useState(true); //Tempo Principal
  const [check02, setCheck02] = useState(true); //Melhorias
  const [check03, setCheck03] = useState(false); //Dev Qualidade
  const [check04, setCheck04] = useState(false); //Internos
  const [check05, setCheck05] = useState(false); //Estimativa
  const [check06, setCheck06] = useState(false); //Correções
  // Seleção de data
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [inputDate, setInputDate] = useState(selectedDate);
  const [diaTrabalhado, setDiaTrabalhado] = useState([]);
  // Status
  const [showHours, setShowHours] = useState(false);
  const [status, setStatus] = useState();
  useEffect(() => {
    setUserInfo(props.id);
  }, []);
  const timeToday = (trabalhado) => {
    let entrada = new Date();
    const agora = new Date();
    const saida = new Date(selectedDate);
    entrada.setHours(9, 30, 0);
    saida.setHours(17, 30, 0); //Uma hora a menos por conta do almoço
    const adjustedTimeAsMs = entrada.getTime() + trabalhado;
    const tempoTrabalhado = new Date(adjustedTimeAsMs);
    const passou = agora - entrada;

    if (isToday(selectedDate)) {
      setDayTime(msToTime(passou));
      setPercent(
        Math.round(((tempoTrabalhado - entrada) / (agora - entrada)) * 100)
      );
    } else {
      entrada = new Date(selectedDate);
      setDayTime("8:00:00");
      setPercent(
        Math.round(((tempoTrabalhado - entrada) / (saida - entrada)) * 100)
      );
    }
  };
  const handleGetHours = async (e) => {
    e.preventDefault();
    getUserHours();
  };
  const handleDateChange = (e) => {
    e.preventDefault();
    const datanova = new Date(e.target.value);
    setSelectedDate(datanova);
    setInputDate(datanova);
  };
  const getUserHours = () => {
    let groups = [];
    // controle_de_tempo -> Horas
    // controle_de_tempo_154 -> Correção Dev
    // dup__of_corre__o_dev -> Analise Dev
    if (check01) {
      groups.push("controle_de_tempo");
    }
    if (check02) {
      groups.push("controle_de_tempo_154");
    }
    if (check03) {
      groups.push("dup__of_corre__o_dev");
    }
    if (check05) {
      groups.push("dup__of_an_lise_dev7");
    }
    if (check06) {
      // Correções
      groups.push("controle_de_tempo_18");
    }
    const $query = `query {
      boards(ids: 492926956) {
        groups(ids: ["novo_grupo85217", "novo_grupo2754", "novo_grupo88078","novo_grupo13949","novo_grupo88676","novo_grupo4541","novo_grupo87118"]) {
          id
          title
          items(newest_first: true,limit : 100) {
            id
            name
            column_values(ids: [${groups}]) {
              title
              id
              value
            }
          }
        }
      }
    }`;
    console.log("Buscando informações do monday...");
    console.log("Buscando informações do dia " + selectedDate.toDateString());
    setStatus("Buscando informações do monday... Isso pode demorar bastante");
    if (groups.length > 0) {
      ApiMonday.post("/", JSON.stringify({ query: $query }))
        .then((response) => {
          console.log("Request do monday completa");
          setStatus("Request do monday completa");
          setTimeout(() => {
            setStatus(null);
          }, 2000);

          let $grupos = response.data.data["boards"][0]["groups"];

          const $queryInternos = `query {
            boards(ids: 2649627865) {
              groups {
                id
                title
                items(newest_first: true,limit : 100) {
                  id
                  name
                  column_values(ids:"controle_de_tempo"){
                    title
                    id
                    value
                  }
                }
              }
            }
          }`;

          function ContaHoras($grupos) {
            const totalTime = timeWorked(
              $grupos,
              selectedDate.getTime(),
              userInfo
            );
            setUserTime(totalTime.tempoFinal);
            setDiaTrabalhado(totalTime);
            timeToday(totalTime["FinalMs"]);
            setShowHours(true);
            return totalTime;
          }
          if (check04) {
            ApiMonday.post("/", JSON.stringify({ query: $queryInternos })).then((internos) => {

              let gruposInternos = [];
              internos.data.data["boards"][0]["groups"].forEach(internoGrupo => {
                gruposInternos.push(internoGrupo);
              });
  
              $grupos = [...$grupos,...gruposInternos];

              let totalTime = ContaHoras($grupos);
              return totalTime;
            }).catch((err) => {
              toast.error("ops! ocorreu um erro" + err);
              return err;
            });
          }else{
            let totalTime = ContaHoras($grupos);
              return totalTime;
          }

        })
        .catch((err) => {
          toast.error("ops! ocorreu um erro" + err);
          return err;
        });
    } else {
      setShowHours(false);
      setStatus("Selecione uma opção no filtro");
      setTimeout(() => {
        setStatus(null);
      }, 2000);
    }
  };
  return (
    <div className="my-3 text-left">
      <div className="grid grid-cols-4 gap-4 items-center mb-5 bg-ebony-800 p-3 rounded-lg">
        <form onSubmit={handleGetHours}>
          <input
            className="button_submit"
            type="submit"
            value="Puxar minhas horas do dia"
          />
        </form>
        <div>
          <div>
            <input
              checked={check01}
              type="checkbox"
              name="controle_de_tempo"
              className="mr-2"
              onChange={(e) => {
                setCheck01(e.target.checked);
              }}
            />
            <label htmlFor="controle_de_tempo">Tempo principal</label>
          </div>
          <div>
            <input
              checked={check04}
              type="checkbox"
              name="internos"
              className="mr-2"
              onChange={(e) => {
                setCheck04(e.target.checked);
              }}
            />
            <label htmlFor="internos">Desenvolvimentos Internos</label>
          </div>
          <div>
            <input
              checked={check02}
              type="checkbox"
              name="controle_de_tempo_154"
              className="mr-2"
              onChange={(e) => {
                setCheck02(e.target.checked);
              }}
            />
            <label htmlFor="controle_de_tempo_154">Tempo em melhorias</label>
          </div>
          <div>
            <input
              checked={check03}
              type="checkbox"
              name="dup__of_corre__o_dev"
              className="mr-2"
              onChange={(e) => {
                setCheck03(e.target.checked);
              }}
            />
            <label htmlFor="dup__of_corre__o_dev">Analise de qualidade</label>
          </div>
          <div>
            <input
              checked={check05}
              type="checkbox"
              name="dup__of_an_lise_dev7"
              className="mr-2"
              onChange={(e) => {
                setCheck05(e.target.checked);
              }}
            />
            <label htmlFor="dup__of_an_lise_dev7">Analise de estimativa</label>
          </div>
          <div>
            <input
              checked={check06}
              type="checkbox"
              name="controle_de_tempo_18"
              className="mr-2"
              onChange={(e) => {
                setCheck06(e.target.checked);
              }}
            />
            <label htmlFor="controle_de_tempo_18">Correções</label>
          </div>
        </div>
        <input
          name="input_date"
          type="date"
          value={inputDate.toISOString().split("T")[0]}
          className="px-5 py-2.5 border-gray-200 border-solid border-2 rounded-lg"
          onChange={handleDateChange}
        />
        {showHours && (
          <div>
            <div>Seu tempo em tarefas hoje: {userTime}</div>
            <div style={{"display":"none"}}>Tempo desde o começo do dia: {dayTime}</div>
            <div className="hidden w-full bg-gray-200 rounded-full dark:bg-gray-50">
              <div
                className="max-w-full bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{ width: percent + "%" }}
              >
                {percent} %
              </div>
            </div>
          </div>
        )}
      </div>

      {status !== null && <div>{status}</div>}
      {showHours && (
        <div className="tempoTotal">
          <div>
            <Tabela Colunas={["Tarefa","Nome do timer","Tempo"]}>
              {diaTrabalhado.tarefas &&
                diaTrabalhado.tarefas.map((tarefa, i) => {
                  return tarefa.map((trf, e) => {
                    return (
                      <tr key={e}>
                        <td>
                          {trf.name}
                        </td>
                        <td>
                          {trf.area}
                        </td>
                        <td>
                          {trf.tempo}
                        </td>
                      </tr>
                    );
                  });
                })}
            </Tabela>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeWorked;
