import React, { useEffect, useState } from "react";
import Changlog from "../components/Changlog";
import Layout from "../components/Layout";
import "../styles/HomePage.scss";
export default function Home() {
  const [today] = useState(new Date());
  const [time,setTime] = useState(new Date().toLocaleTimeString());
  useEffect(() => {
    // eslint-disable-next-line
    const clockInterval = setInterval(() => {tikClock()},1000);
  }, [])
  
  const tikClock = () =>{
    setTime(new Date().toLocaleTimeString());
  }
  
  return (
    <Layout>
      <div className="container mx-auto HomePage">
        <h1>{today.getHours() >= 12 ? "Boa tarde" : "Bom dia"}</h1>
        <h2>
          <span>
            {today.getDate() +
              "/" +
              (today.getMonth() + 1) +
              "/" +
              today.getFullYear()}
          </span>
          <span className="ml-3">
              {time}
          </span>
        </h2>
        <br />
        <Changlog />
      </div>
    </Layout>
  );
}
