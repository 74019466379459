import "./styles/App.scss";
// 
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header";

// Toastfy
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { UserContextProvider } from "./context/UserContext";
import Router from "./components/Router";

// App
export default function App() {
  return (
    <div className="App min-h-screen block">
      <ToastContainer theme={"dark"} />
      <BrowserRouter basename="/">
        <UserContextProvider>
          <Header />
          <Router />
        </UserContextProvider>
      </BrowserRouter>
    </div>
  );
}
