import { useEffect, useState } from 'react';
import Tabela from '../../../components/Layout/Tabela';
import Api from '../../../services/ApiSerie';
import styles from './userAdmin.module.scss'
interface UsersInterfaces {
    id: number;
    mondayId: number;
    name: string;
    email: string
    roles: string[];
    permissions: string[];
}


const UserTable = () => {
    const [users, setUsers] = useState<UsersInterfaces[]>([]);
    useEffect(() => {
        Api
            .get("user/")
            .then((response) => {
                const data = response.data.map((resp: any) => {
                    return {
                        email: resp.email,
                        id: Number(resp.id),
                        mondayId: Number(resp.mondayId),
                        name: resp.name,
                        roles: JSON.parse(resp.roles),
                        permissions: JSON.parse(resp.permissions),
                    }
                })
                setUsers(data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                return err;
            });
    }, [])

    return (
        <Tabela Colunas={["id", "mondayId", "Nome", "Email", "Permissions", "Roles", "Ações"]}>
            {users &&
                users.map((user, i) => {
                    return <tr key={user.id}>
                        <td>
                            {user.id}
                        </td>
                        <td>
                            {user.mondayId}
                        </td>
                        <td>
                            {user.name}
                        </td>
                        <td>
                            {user.email}
                        </td>
                        <td>
                            {user.permissions && user.permissions.map((perm, p) => {
                                return <span className={styles.tag + " " + perm}>{perm}</span>
                            })}
                        </td>
                        <td>
                            {user.roles && user.roles.map((role, r) => {
                                return <span className={styles.tag + " " + role}>{role}</span>
                            })}
                        </td>
                        <td>

                        </td>
                    </tr>
                })}
        </Tabela>
    )
}

export default UserTable