import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import 'flowbite';
// React Query
import { QueryClientProvider } from 'react-query';
import {queryClient} from './services/queryClient';
import { CookiesProvider } from 'react-cookie';
// import { ReactQueryDevtools } from "react-query/devtools";
// Fim React Query

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <App/>
    </QueryClientProvider>
    </CookiesProvider>
  </React.StrictMode>,
);