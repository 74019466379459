import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiMonday from "../../services/ApiMonday";

export default function UserInfo(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    var query = `query {users(ids: ${props.userId}){name,id,title,email,photo_original,birthday,created_at}}`;
    ApiMonday.post("/", JSON.stringify({ query: query }))
      .then((response) => {
        setUser(response.data.data.users[0]);
      })
      .catch((err) => {
        toast.error("ops! ocorreu um erro" + err);
        return err;
      });
      // eslint-disable-next-line
  }, []);

  return (
    <div className="flex items-center mb-5">
      <div className="flex items-center">
        {user && (
          <img
            className="w-auto"
            style={{ maxWidth: "7rem" }}
            src={user.photo_original}
            alt={user.name}
          />
        )}
      </div>
      {user && (
        <div className="flex items-center flex-col justify-center text-left ml-5">
          <h1 className="w-full block">👤 {user.name}</h1>
          <h2 className="w-full block">✉️ {user.email}</h2>
          <h2 className="w-full block">🎂 {user.birthday}</h2>
        </div>
      )}
    </div>
  );
}
